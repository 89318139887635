import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Icon from 'react-icons-kit';
import Box from '../../components/Box';
import Text from '../../components/Text';
import Heading from '../../components/Heading';
import Image from '../../components/Image';
import Button from '../../components/Button';
import ErrorImage from '../../assets/images/404.svg';
import { home } from 'react-icons-kit/entypo/home';
import { ccw } from 'react-icons-kit/entypo/ccw';
import { ErrorWrapper, ErrorConatent, ButtonWrapper } from './error.style';
import Layout from '../Layout';
import { enFr } from '../../utils';

let lang = process.env.GATSBY_LANG;

const ErrorSec = ({ imageWrapper, title, text, reloadButton, homeButton }) => {
  const pageReload = () => {
    window.location.reload();
  };
  return (
    <Layout>
      <ErrorWrapper>
        <ErrorConatent>
          <Heading
            {...title}
            content={enFr(
              "Sorry, that page wasn't found",
              "Désolé, cette page n'a pas été trouvée"
            )}
          />
          <Text
            {...text}
            content={enFr(
              "It seems the page you're trying to visit doesn't exist",
              "Il semble que la page que vous essayez de visiter n'existe pas"
            )}
          />
          <ButtonWrapper>
            <Link href="/">
              <Button
                {...homeButton}
                title={enFr('Go Home', 'Accueil')}
                icon={<Icon icon={home} size={24} />}
                className="domain_search_button"
              />
            </Link>
          </ButtonWrapper>
        </ErrorConatent>
      </ErrorWrapper>
    </Layout>
  );
};

ErrorSec.propTypes = {
  imageWrapper: PropTypes.object
};

ErrorSec.defaultProps = {
  imageWrapper: {
    mb: ['40px', '55px']
  },
  title: {
    fontSize: ['26px', '32px', '38px', '48px'],
    color: '#ffffff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '35px'],
    lineHeight: '1.31',
    textAlign: 'center',
    fontFamily: 'opensans',
    fontWeight: '500'
  },
  text: {
    fontSize: ['16px', '20px', '20px', '20px', '20px'],
    color: '#ffffff',
    lineHeight: '2',
    mb: ['30px', '40px', '50px', '60px'],
    textAlign: 'center',
    fontFamily: 'opensans'
  },
  reloadButton: {
    type: 'button',
    fontSize: '16px',
    fontWeight: '500',
    color: '#fff',
    pl: ['15px', '22px'],
    pr: ['15px', '22px'],
    iconPosition: 'left',
    bg: '#eaa03b',
    fontFamily: 'opensans'
  },
  homeButton: {
    type: 'button',
    fontSize: '16px',
    fontWeight: '500',
    color: '#fff',
    backgroundColor: '#333',
    pl: ['15px', '22px'],
    pr: ['15px', '22px'],
    iconPosition: 'left',
    bg: '#e2e7f0',
    fontFamily: 'opensans'
  }
};

export default ErrorSec;
